<template>
  <div>
    <Header />
    <SuspensionConsult />
    <div class="about_wrap">
      <div class="about_cont">
        <p class="text1">葫芦工业云简介</p>
        <p class="text2">
          葫芦工业云是一个集云边市场、应用于一体的工业化平台，打通了云、边、端三层相互协同，PC端、手机端等多端协同管理。
        </p>
      </div>
      <img class="aboutUs_wrap_bg" src="@/assets/aboutUs_bg.png" alt="" />
    </div>
    <div class="intro_wrap">
      <p class="intro_title">葫芦工业云简介</p>
      <p class="intro_des">
        葫芦工业云打通了云、边、端一体化的协同交互，并且提供了PC端、手机端等多端的管理，随时查看企业情况。
      </p>
      <p class="intro_des">
        云平台服务，专注于工业领域的云服务系统框架，葫芦云上传的设备数据，具备高稳定性、高安全性、支持高并发量的特点。云端对边缘端、终端设备进行统一管理，统一维护，方便快捷
      </p>
      <p class="intro_des">
        葫芦工业云平台提供了专属的云边应用市场，包含多种生产应用，并且实现了边缘端应用的一键部署，企业用户只需要购买后点击实施部署，即可轻松部署到边缘端。
      </p>
      <p class="intro_des">
        边缘端自成系统，弱网或断网与云端连接中断时不影响边缘端业务运行，边缘端重启时无需从云端获取数据。边缘端保证了数据及时性，满足了部分设备以及系统对数据实时性的要求。
      </p>
      <p class="intro_des">
        终端设备，百万级集群规模能力，可以自定义设备模型并通过设备孪生技术实现与物理设备解耦，屏蔽底层接入协议。
      </p>
    </div>
    <div class="certificate_wrap">
      <p class="certificate_title">资质荣誉证书</p>
      <p class="certificate_subtitle">Honorary certificate</p>
      <div class="certificate_cont">
        <div class="certificate_item" v-for="(item, index) in certificateList" :key="index">
          <img class="item_img" :src="item.path" alt="" />
          <p class="item_name">{{item.name}}</p>
        </div>
      </div>
    </div>
    <div class="other_wrap">
      <div class="other_cont">
        <div class="other_item">
          <div class="imgBox">
            <img class="consultUs_img" src="@/assets/consultUs.png" alt="" />
          </div>
          <p class="item_name">咨询我们</p>
          <p class="item_subname">为你提供售前咨询，解决方案等服务</p>
          <div class="usMsg_cont">
            <p class="usMsg">
              <span class="msg_name">联系电话 ：</span>
              <span class="msg_val">021-39196758 18121114640</span>
            </p>
            <p class="usMsg">
                <span class="msg_name">公司邮箱 ：</span>
                <span class="msg_val">support@boyuan-sh.com</span>
            </p>
            <p class="usMsg">
              <span class="msg_name">公司地址 ：</span>
              <span class="msg_val">上海市嘉定区墨玉南路1033号嘉亭大厦511室</span>
            </p>
          </div>
        </div>
        <div class="other_item">
          <div class="imgBox">
            <img class="joinUs_img" src="@/assets/joinUs.png" alt="" />
          </div>
          <p class="item_name">加入我们</p>
          <p class="item_subname">有意向加入我们葫芦云团队</p>
          <button class="pointer btn" @click="seeJob">看看岗位</button>
        </div>
        <div class="other_item">
          <div class="imgBox">
            <img
              class="skillSupport_img"
              src="@/assets/skillSupport.png"
              alt=""
            />
          </div>
          <p class="item_name">技术支持</p>
          <p class="item_subname">为您提供技术产品使用问题</p>
          <button class="pointer btn" @click="$bus.$emit('showApply')">申请演示</button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header";
import Footer from "@/components/footer";
import SuspensionConsult from "@/components/suspensionConsult";
export default {
  components: {
    Header,
    Footer,
    SuspensionConsult,
  },
  methods:{
    seeJob(){
        this.$router.push({
            path:'/recruitment'
        })
    },
  },
  data() {
    return {
      certificateList: [
        {
          "path":require("@/assets/certificate/alarm.jpg"),
          "name":"Hulu 生产报警监控系统"
        },
        {
          "path":require("@/assets/certificate/atalas.jpg"),
          "name":"Hulu-阿特拉斯拧紧枪数据采集和分析系统"
        },
        {
          "path":require("@/assets/certificate/nxt.jpg"),
          "name":"Hulu-FUJI NXT贴系统"
        },
        {
          "path":require("@/assets/certificate/edge_barcode.jpg"),
          "name":"Hulu-边缘条码识别系统"
        },
        {
          "path":require("@/assets/certificate/file_beats.jpg"),
          "name":"Hulu-通用文件数据解析系统"
        },
        {
          "path":require("@/assets/certificate/masterdata.jpg"),
          "name":"Hulu-离散制造业通用基础数据平台"
        },
        {
          "path":require("@/assets/certificate/intelligent_device_management.jpg"),
          "name":"Hulu 智能设备管理系统"
        },
        {
          "path":require("@/assets/certificate/trace_mobile_display.jpg"),
          "name":"Hulu 生产追溯移动展示系统"
        },
        {
          "path":require("@/assets/certificate/implement_platform.jpg"),
          "name":"Hulu 生产制造执行平台"
        },
        {
          "path":require("@/assets/certificate/iot.jpg"),
          "name":"Hulu 生产追溯边缘采集系统"
        },
        {
          "path":require("@/assets/certificate/impliment_data_acquisition.jpg"),
          "name":"Hulu 生产制造执行数据采集软件"
        },
        {
          "path":require("@/assets/certificate/workorder_monitor.jpg"),
          "name":"Hulu 生产工单监控系统"
        },
        {
          "path":require("@/assets/certificate/human-computer_interaction.jpg"),
          "name":"Hulu 生产作业人机交互系统"
        },
        {
          "path":require("@/assets/certificate/rework.jpg"),
          "name":"Hulu 自动化设备接口调试系统"
        },
        {
          "path":require("@/assets/certificate/interface_debug.jpg"),
          "name":"Hulu 生产报警监控系统"
        },
        {
          "path":require("@/assets/certificate/operation_instruction_system.jpg"),
          "name":"Hulu 生产作业指导系统"
        },
        {
          "path":require("@/assets/certificate/device_status_monitor.jpg"),
          "name":"Hulu 生产设备状态监控系统"
        },
        {
          "path":require("@/assets/certificate/data_analysis.jpg"),
          "name":"Hulu 生产数据分析系统"
        },
        {
          "path":require("@/assets/certificate/visualization.jpg"),
          "name":"Hulu 生产可视化系统"
        },
        {
          "path":require("@/assets/certificate/market.jpg"),
          "name":"Hulu 工业软件市场管理平台"
        }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>